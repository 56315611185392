/**
 * Styles targeting the Biblio related content
 *
 */

/* =Publications
----------------------------------------------------------*/

.publication-teaser {
  margin: 0 0 10px;
}

.view-publications__group {
  color: #000000;
  font-weight: bold;
  background-color: #e1e1e1;
  border: 1px solid #ccc;
  padding: 10px 15px 5px;
  margin: 20px 0;
}
