/**
 * Shared styles to apply to different components
 *
 */

/* =Teaser Styles
----------------------------------------------------------*/

@mixin teaser-space {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid $wcm-border-gray;
  &:last-child {
    border: none;
  }
}

@mixin teaser-title {
  font-size: 20px;
  padding: 0 0 10px;
  color: $wcm-red;
  font-family: $wcm-bold;
  a {
    color: $wcm-red;
  }
}

@mixin teaser-date {
  text-transform: uppercase;
  color: $wcm-med-gray;
  font-family: $wcm-bold;
  font-size: 13px;
  letter-spacing: 2px;
  padding-bottom: 10px;
}
