/**
 * Styles targeting the Paragraph bundles used on the site
 *
 */

/* =Misc
----------------------------------------------------------*/

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* =Sections
----------------------------------------------------------*/

.paragraph-section__title {
  border-top: 5px solid $wcm-red;
  text-align: center;
  span {
    display: inline-block;
    background: $wcm-red;
    color: $wcm-white;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: -1px 0 0;
    padding: 5px 20px;
    letter-spacing: 2px;
    @include breakpoint($md) {
      margin: 0;
    }
  }
}

/* =Bar Callouts
----------------------------------------------------------*/

.bar-callout .row.is-flex {
  @include breakpoint($xs) {
    display: block;
  }
}

.bar-panel--title {
  a {
    &:after {
      content: '';
    }
  }
  @include breakpoint($sm) {
    background: $wcm-bg-gray;
  }
}

.bar-panel--title--linked {
  cursor: pointer;
}

.bar-callout .row {
  &:hover {
    .bar-callout__image img {
        transform: scale(1.1);
    }
  }
}

.bar-callout__text {
  margin-bottom: 20px;

  @include breakpoint($sm) {
    text-align: center;
    @include center();
    width: 70%;
    margin: 0;
    font-size: 13px;
  }
  @include breakpoint($md) {
    font-size: 15px
  }
  a.external-link:after {
    @include wcm-external-icon();
  }
}

.bar-callout__title {
  font-size: 15px;
  @include breakpoint($md) {
    font-size: 18px;
  }
  a {
    color: $wcm-red;
    &:hover {
      // color: $wcm-bright-orange;
      text-decoration: underline;
    }
  }
}

.bar-callout__description {
  color: $wcm-med-gray;
  margin: 5px 0 0;
  @include breakpoint($md) {
    font-size: 18px;
    line-height: 1.6;
  }
  &:hover {
    text-decoration: underline;
  }
}

.bar-callout__image {
  overflow: hidden;
  a {
    display: block;
  }
}

.bar-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
  padding: 0;
  &:hover {
    transform: scale(1.1);
  }
}
