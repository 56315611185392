/**
 * Styles targeting the various site Callouts
 *
 */

/* =Border Callout
----------------------------------------------------------*/

.border-callout {
  padding: 20px 0;
  margin: 20px 0;
  border: 2px solid $wcm-border-gray;
  border-width: 2px 0;
  .row.is-flex {
    display: block;
    align-items: center;
    @include breakpoint($md) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .wcm-cta__tile {
    width: auto;
    height: auto;
    border-width: 0 0 0 0;
    display: none;
    @include breakpoint($md) {
      display: block;
      height: 80px;
      border-width: 0 1px 0 0;
    }
    span {
      text-align: left;
      @include breakpoint($md) {
        text-align: center;
      }
    }
  }
  .wcm-cta__detail {
    position: relative;
    transform: none;
    width: auto;
    left: 0;
    @include breakpoint($md) {
      width: 80%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
  h3 {
    @include breakpoint($md) {
      padding-left: 20px;
      margin: 0;
    }
    a:hover {
      @include breakpoint($md) {
        text-decoration: underline;
      }
    }
  }
  p {
    @include breakpoint($md) {
      padding-left: 20px;
    }
  }
}
