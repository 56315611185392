/**
 * Styles targeting the Home page
 *
 */

/* =Preventing Childhood Diseases Pane
----------------------------------------------------------*/

.front {
  background-color: $wcm-white;
}


.wcm-drukier-home {
  position: relative;

  #carousel {
    margin-bottom: 20px;
  }

  .home-message {
    text-align: center;
    padding: 30px 0 0;
    @include container-wrap();
    h1 {
      font-size: 30px;
      margin: 0 0 10px;
    }
    p {
      color: $wcm-dark-gray;
      font-family: $wcm-regular;
      font-size: 20px;
      line-height: 1.5;
      padding: 0 17.5%;
    }
  }

  //Fontello font for cta's
  @import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/fonts/fontello/css/fontello-embedded";

  #middle-section {
    .view-content {
      @include breakpoint($md) {
        display: flex;
        align-items: stretch;
      }
    }

    .news {
      h2 {
        @include breakpoint($xs) {
          text-align: center;
        }
        @include breakpoint($sm-only) {
          text-align: center;
        }
        a {
          &:hover {
            text-decoration: none;
            border-bottom: 1px solid !important;
          }
        }
      }
    }

    .featured-news-item-no-results {
      font-size: $font-size-h4;
      margin: 20px 0;
    }

    .featured-news-item {
      margin-top: 20px;
      @include breakpoint($md) {
        margin: 25px 25px 10px 0;
        border: none;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .featured-news-item-container {
      background: #fff;
      box-shadow: 1px 2px 2px 1px #dddddd;
      height: 100%;
    }

    .featured-news-item-text {
      padding: 20px;
    }

    .title {
      margin: 0 0 10px;
      text-align: center;
      a {
        color: $wcm-red;
        &:hover {
          color: $wcm-red;
          text-decoration: underline;
          &:before {
            text-decoration: none;
          }
        }
        &:after {
          content: '' !important;
          display: none !important;
        }
      }
      a span {
        display: block;
        position: relative;
        top: -40px;
        margin: 0 auto -40px;
      }
      a span:before {
        content: '\e802';
        width: 50px;
        height: 50px;
        color: $wcm-bright-orange;
        background: #fff;
        border-radius: 50%;
        font-size: 20px;
        text-align: center;
        @include fontello();
        line-height: 50px;
        display: inline-block;
        @include transition(background-color .25s);
      }
      a.external-link span:before {
        content: '' !important;
        background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 15px 15px;
        margin-left: 6px;
        position: relative;
        top: 2px;
        vertical-align: middle;
      }
      a:hover span:before {
        text-decoration: none;
      }
    }

    .description {
      font-size: 15px;
      text-align: center;
    }

    .featured-news-item-image {
      overflow: hidden;
    }

    .featured-news-item-image img {
      width: 100%;
      transition: all 0.3s ease 0s;
      margin-bottom: 0;
      &:hover {
        transform: scale(1.1);
      }
    }

    .wcm-upcoming-events {
      margin-top: 30px;
      @include breakpoint($md) {
        margin-top: 40px;
      }
      .wcm-upcoming-event {
        padding: 13px 30px 13px 0;
      }
    }
  }

  #middle-callout {
    padding-top: 25px;
    padding-bottom: 30px;

    /* =Override _paragraph_bundles.scss */
    @include breakpoint($md) {
      .bar-callout {
        .container {
          padding-right: 0;
          padding-left: 0;
          margin-left: 0;
          margin-right: 0;
        }
        .row {
          min-height: 315px;
          img {
            min-height: 315px;
          }
        }
      }
    }

    @include breakpoint($xs) {
      .bar-panel--title {
        a {
          &:after {
            content: "\e80d";
          }
        }
      }
    }
    @include breakpoint($sm) {
      .bar-panel--title {
        a {
          &:after {
            content: "\e80d";
            color: $wcm-white;
            line-height: 1.6;
          }
        }
      }
      .panel-pane {
        &:nth-child(odd) {
          .bar-panel--title {
            background-color: $wcm-red;
          }
        }

        &:nth-child(even) {
          .bar-panel--title {
            background-color: $wcm-dark-orange;
          }
        }
      }
    }
    @include breakpoint($md) {
      .bar-panel--title {
        a {
          &:after {
            line-height: 2;
          }
        }
      }
    }

    .bar-callout__image {
      a {
        @include breakpoint($sm) {
          border-bottom: none;
        }
      }
    }

    .bar-callout__text {
      @include breakpoint($sm) {
        text-align: left;
        width: 75%;
      }
    }

    .bar-callout__title {
      @include breakpoint($sm) {
        margin-top: 0;
        a {
          color: $wcm-white;
        }
      }
      @include breakpoint($md) {
        margin-top: 0;
        font-size: 30px;
        padding-bottom: 5px;
      }
    }

    .bar-callout__description {
      @include breakpoint($sm) {
        color: $wcm-white;
      }
      @include breakpoint($md) {
        font-size: 14px;
        line-height: 1.8;
      }
    }
    /* =End Override _paragraph_bundles.scss */
  }

  #bottom-row {
    color: $wcm-white;
    background-color: $wcm-dark-orange;
    padding: 20px 0;

    .container .row {
      @include breakpoint($md) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .image-container {
      padding-left: 40px;

      @include breakpoint($xs) {
        padding: 20px 20px;
      }
      @include breakpoint($sm-only) {
        padding: 20px 5px 20px 40px;
      }

      img {
        margin-bottom: 0;
      }
    }
    .ways-to-give {
      .pane-bundle-text {
        width: 100%;
        text-align: left;
        font-weight: 300;
        margin: 10px 0;
      }
      h3 {
        color: $wcm-white;
        font-size: $font-size-h2;
        margin: 0 0 10px 0;
      }
      .btn {
        color: $wcm-white;
        border-color: $wcm-white;
        border-radius: 20px;
        padding: 10px 20px;
        margin: 25px 0 0 0;
        &::before {
          top: 5px;
        }
      }
    }
  }
}
