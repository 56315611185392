/**
 * Styles targeting the News & Updates section
 *
 */

/* =News Image Teasers
----------------------------------------------------------*/

.image-teaser {
  @include teaser-space;
}

.image-teaser__title {
  @include teaser-title;
  a {
    color: $wcm-red;
    &:after {
      content: '\e80d';
      color: $wcm-bright-orange;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: top;
      @include fontello();
      text-decoration: none;
      line-height: 2;
    }
  }
}

.image-teaser__date {
  @include teaser-date;
}
