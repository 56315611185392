/**
 * Styles targeting various site elements
 *
 */

/* =Typography
 ----------------------------------------------------------*/

@mixin wcm_external_icon() {
  display: inline-block;
  content: '' !important;
  background-image: url('/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/external-link.svg');
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  margin-left: 6px;
  position: relative;
  top: 2px;
  @include breakpoint($md) {
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
  }
}

.wcm-edu-external-link:after {
  @include wcm_external_icon();
}

/* =Footer
----------------------------------------------------------*/

.footer-nav {
  @include breakpoint($md) {
    margin: 0 0 20px;
  }
  li {
    line-height: 1.4;
    padding: 0 0 8px;
  }
}

.footer-nav .level-1 {
  @include breakpoint($md) {
    width: (100% / 5);
    float: left;
    padding: 0 10px 5px;
  }
}
